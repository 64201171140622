<template>
    <div class="blue-gradient">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="d-flex flex-column col-sm-12 col-md-8 col-lg-5">
                            <div class="card login-card">
                                <div class="card-header">
                                    <img src="https://medshift-cdn.nyc3.cdn.digitaloceanspaces.com/MEDSHIFT+Transparent.png"
                                         style="height: 35px"
                                         alt="MedShift">
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col">
                                            <div
                                                class="d-flex justify-content-center align-items-center"
                                            >
                                                <div class="d-flex flex-column">
                                                    <div class="p-2 m-auto">
                                                        <button class="btn btn-sm btn-primary btn"
                                                                @click="navigateToLogin" >
                                                            <i class="fab fa-google mr-2" /> Admin login
                                                        </button>
                                                    </div>
                                                    <div class="p-2">
                                                        <small
                                                            class="form-text text-muted text-center"
                                                        >Login with MedShift account.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { navigateToLogin } from '../services/AuthService';

export default {
    name: "Login",
    methods: {
        navigateToLogin,
    },
};
</script>

<style scoped>
.blue-gradient {
    height: 100vh;
    background: linear-gradient(to right, #0575e6, #021b79);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.login-card {
    margin-top: 35vh;
}
</style>

